'use client';

import { useState } from 'react';

import { PaperclipIcon, TrophyIcon } from 'lucide-react';
import { User } from 'next-auth';
import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { Label } from 'recharts';

import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { Icons } from '@/components/ui/icons';
import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/components/ui/use-toast';
import { useRedirectUrl } from 'context/redirectUrlProvider';
import { getPublicEnvVariable } from 'lib/utilsClientSide';

import AppFeedback from '../atoms/AppFeedback';
import { LoginModal } from '../account/LoginModal';

interface UserAccountNavProps extends React.HTMLAttributes<HTMLDivElement> {
  user: User;
  userLevel: number;
  setUserLevel: React.Dispatch<React.SetStateAction<number>>;
}

const generateReferralLink = (user: User) => {
  const userId = user?.email;
  const baseUrl = `https://${window.location.hostname}`;

  return `${baseUrl}?ref=${userId}`;
};

export function UserAccountNav({ user, userLevel, setUserLevel }: UserAccountNavProps) {
  const [gamificationModalOpen, setGamificationModalOpen] = useState<boolean>(false);
  const t = useTranslations('Profile');
  const { toast } = useToast();
  const [, , setRedirectUrlToCurrentPage] = useRedirectUrl();
  const [showLogin, setShowLogin] = useState<boolean>(false);

  if (!user) {
    return (
      <>
        <Button
          className="max-md:hidden"
          onClick={() => {
            setRedirectUrlToCurrentPage();
            setShowLogin(true);
          }}
        >
          {t('register')}
        </Button>
        {showLogin && <LoginModal onClose={() => setShowLogin(false)} />}
      </>
    );
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="rounded-full focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2">
          <Avatar className="h-8 w-8">
            {user.image ? (
              <AvatarImage alt="Picture" src={user.image} />
            ) : (
              <AvatarFallback>
                <span className="sr-only">{user.name || null}</span>
                <Icons.user className="h-4 w-4" />
              </AvatarFallback>
            )}
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <div className="flex items-center justify-start gap-2 p-2">
            <div className="flex flex-col space-y-1 leading-none">
              {user.name && <p className="font-medium">{user.name}</p>}
              {user.email && (
                <p className="w-[200px] truncate text-sm text-muted-foreground">
                  {user.email}
                </p>
              )}
            </div>
          </div>
          {getPublicEnvVariable(
            process.env.NEXT_PUBLIC_FEATURE_FLAG_GAMIFICATION,
            'NEXT_PUBLIC_FEATURE_FLAG_GAMIFICATION'
          ) && (
            <DropdownMenuItem
              className="cursor-pointer"
              onSelect={(event) => {
                event.preventDefault();
                setGamificationModalOpen(true);
              }}
            >
              <div className="flex flex-col justify-start w-full">
                {/*Display of userLevel bar with 4 sections*/}
                <Label className="flex flex-row items-center pb-1 pl-0.5 font-medium">
                  {t('level')} {userLevel}
                </Label>
                {/*rounded gauge with half filled bar*/}
                <div className="flex flex-row  items-start border-2 rounded-full overflow-hidden gap-1 w-full">
                  <div className="flex flex-row items-center justify-between w-1/4">
                    <div
                      className={`w-full h-3 ${userLevel >= 1 ? 'bg-orange' : 'bg-secondary'}`}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between w-1/4">
                    <div
                      className={`w-full h-3 ${userLevel >= 2 ? 'bg-orange' : 'bg-secondary'}`}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between w-1/4">
                    <div
                      className={`w-full h-3 ${userLevel >= 3 ? 'bg-orange' : 'bg-secondary'}`}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between w-1/4">
                    <div
                      className={`w-full h-3 ${userLevel >= 4 ? 'bg-orange' : 'bg-secondary'}`}
                    />
                  </div>
                </div>
              </div>
            </DropdownMenuItem>
          )}

          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="cursor-pointer"
            onSelect={async (event) => {
              event.preventDefault();
              await signOut({
                callbackUrl: `${window.location.origin}/`,
              });
            }}
          >
            {t('logOut')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={gamificationModalOpen} onOpenChange={setGamificationModalOpen}>
        <DialogContent>
          <Toaster />

          <DialogHeader>
            <DialogTitle>{t('Gamification.title')}</DialogTitle>
            <DialogDescription>{t('Gamification.subtitle')}</DialogDescription>
          </DialogHeader>
          <div className="pt-5 pb-2">
            <div
              className={`flex items-center my-2 pb-1.5 border-b ${userLevel !== 1 && 'opacity-30'}`}
            >
              <div className="flex flex-col">
                <div className="flex  gap-2.5 items-center">
                  <h3 className="text-lg font-semibold text-gray-700 text-nowrap dark:text-white">
                    {t('Gamification.level1.title')}
                  </h3>
                  <p className="ml-2 text-sm text-gray-600 dark:text-gray-300">
                    {t('Gamification.level1.action')}
                  </p>
                </div>
                <p className="text-sm font-medium">{t('Gamification.level1.subtitle')}</p>
              </div>
              <div className="ml-auto">
                <AppFeedback
                  text={t('Gamification.level1.btn')}
                  landingPage={false}
                  user={user}
                  onSubmit={() => setUserLevel(userLevel + 1)}
                  disabled={userLevel !== 1}
                />
              </div>
            </div>

            <div
              className={`flex items-center my-2 pb-1.5 border-b ${userLevel !== 2 && 'opacity-50'} ${userLevel < 2 && 'blur-sm'}`}
            >
              <div className="flex flex-col">
                <div className="flex gap-2.5 items-center">
                  <h3 className="text-lg font-semibold text-gray-700 text-nowrap">
                    {t('Gamification.level2.title')}
                  </h3>
                  <p className="ml-2 text-sm text-gray-600">
                    {t('Gamification.level2.action')}
                  </p>
                </div>
                <p className="text-sm font-medium">{t('Gamification.level2.subtitle')}</p>
              </div>
              <Button
                variant="outline"
                className="ml-auto"
                disabled={userLevel !== 2}
                onClick={async () => {
                  toast({
                    title: t('Gamification.level2.toast'),
                    duration: 5000,
                  });
                  //copy public url in paperclip
                  await navigator.clipboard
                    .writeText(generateReferralLink(user))
                    .catch((err) => console.error('Failed to write to clipboard: ', err));
                }}
              >
                {t('Gamification.level2.btn')}
                <PaperclipIcon className="h-4 w-4 ml-2" />
              </Button>
            </div>

            <div
              className={`flex items-center my-2 pb-1.5 border-b ${userLevel !== 3 && 'opacity-50'} ${userLevel < 3 && 'blur-sm'}`}
            >
              <div className="flex flex-col">
                <div className="flex gap-2.5 items-center">
                  <h3 className="text-lg font-semibold text-nowrap">
                    {t('Gamification.level3.title')}
                  </h3>
                  <p className="ml-2 text-sm text-gray-600">
                    {t('Gamification.level3.action')}
                  </p>
                </div>
                <Button variant="link" className="ml-auto p-0 m-0 w-fit h-fit">
                  {t('Gamification.level3.btn')}
                </Button>
              </div>
            </div>
          </div>

          <DialogFooter>
            <div className="flex flex-col justify-start w-full relative">
              {/*Display of userLevel bar with 4 sections*/}
              <Label className="flex flex-row items-center font-medium pb-1 pl-0.5">
                {t('level')} {userLevel}
              </Label>
              {/*rounded gauge with half filled bar*/}
              <div className="flex flex-row  items-start border-2 rounded-full overflow-hidden gap-1 w-full">
                <div className="flex flex-row items-center justify-between w-1/4">
                  <div
                    className={`w-full h-3 ${userLevel >= 1 ? 'bg-orange' : 'bg-secondary'}`}
                    onClick={() => setUserLevel(userLevel - 1)}
                  />
                </div>
                <div className="flex flex-row items-center justify-between w-1/4">
                  <div
                    className={`w-full h-3 ${userLevel >= 2 ? 'bg-orange' : 'bg-secondary'}`}
                  />
                </div>
                <div className="flex flex-row items-center justify-between w-1/4">
                  <div
                    className={`w-full h-3 ${userLevel >= 3 ? 'bg-orange' : 'bg-secondary'}`}
                  />
                </div>
                <div className="flex flex-row items-center justify-between w-1/4">
                  <div
                    className={`w-full h-3 ${userLevel >= 4 ? 'bg-orange' : 'bg-secondary'}`}
                  />
                </div>
              </div>
              <div className="absolute -right-2 top-1/3 shadow-sm self-center bg-white border-2 rounded-full p-1.5">
                <TrophyIcon
                  className="h-4 w-4 text-orange"
                  onClick={() => setUserLevel(userLevel + 1)}
                />
              </div>
            </div>
            <Label className="self-center text-nowrap pt-3.5 pl-3">
              {t('Gamification.champion')}
            </Label>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
