import type { NextApiRequest, NextApiResponse } from 'next';

import { z } from 'zod';

import prisma from 'utils/prisma';
import { format } from 'date-fns';
import { sendEmail } from 'lib/sendEmail';
import { getBaseUrl } from 'lib/utilsClientSide';
import { fr } from 'date-fns/locale';

const MODE_FEEDBACK = 'feedback';
const MODE_QUOTE = 'quote';
const AVAILABLE_MODES = [MODE_FEEDBACK, MODE_QUOTE] as const;
const MAX_FEEDBACK_SIZE = 1000;

const SLACK_FEEDBACK_HOOK_URL =
  'https://hooks.slack.com/services/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';

export interface QuoteTripDetails {
  origin: string;
  destination: string;
  journeyId?: string;
  departureDate: Date;
  returnDate?: Date;
  adults: number;
  youth: number;
  children: number;
  infant: number;
  bike: number;
  cabinType?: string;
}

export interface PricedQuoteDetails extends QuoteTripDetails {
  price: number;
  commissionRateInPercent: number;
  firstName: string;
}

const quoteTripSchema = z.object({
  origin: z.string(),
  destination: z.string(),
  journeyId: z.string(),
  departureDate: z.coerce.date(),
  returnDate: z.coerce.date().optional(),
  adults: z.number(),
  youth: z.number(),
  children: z.number(),
  infant: z.number(),
  bike: z.number(),
  cabinType: z.string(),
});

const schema = z.object({
  mode: z.enum(AVAILABLE_MODES),
  email: z.string().email({ message: 'Invalid email address' }).or(z.literal('')),
  feedback: z.string().max(MAX_FEEDBACK_SIZE).optional(),
  quote: quoteTripSchema.optional(),
});

export default async function handler(
  request: NextApiRequest,
  response: NextApiResponse
) {
  const { mode, email, feedback, quote } = schema.parse(JSON.parse(request.body));

  try {
    await Promise.all([
      pushToDatabase(mode, email, feedback),
      notifyTheTeam(mode, email, feedback, quote),
      sendConfirmationEmail(mode, email, quote),
    ]);
    response.status(200).json({ message: 'Ok.' });
  } catch (error) {
    console.error(`Error processing user inputs: ${error}`);
    response.status(500).json({ message: 'Error.' });
  }
}

async function pushToDatabase(
  mode: string,
  email: string,
  feedback: string | undefined
): Promise<void> {
  if (mode == MODE_FEEDBACK && feedback) {
    const feedbackRecord = {
      feedback: feedback,
      email: email,
    };
    await prisma.feedback.create({ data: feedbackRecord });
  }
}

async function notifyTheTeam(
  mode: string,
  email: string,
  feedback: string | undefined,
  quote: QuoteTripDetails | undefined
): Promise<void> {
  if (mode == MODE_FEEDBACK && feedback) {
    const slackMessageBody = {
      text: `Feedback: 👀 ${feedback}`,
      attachments: [
        {
          text: email,
        },
      ],
    };

    await postSlackMessage(slackMessageBody);
  } else if (mode == MODE_QUOTE && quote) {
    const formatPassengers = (quote) => {
      const passengerTypes = [
        { type: 'Adult', count: quote.adults },
        { type: 'Youth', count: quote.youth },
        { type: 'Child', count: quote.children },
        { type: 'Infant', count: quote.infant },
        { type: 'Bike', count: quote.bike },
      ];

      return passengerTypes
        .filter(({ count }) => count > 0)
        .map(({ type, count }) => `\t${type}${count > 1 ? 's' : ''}: ${count}`)
        .join('\r\n');
    };

    const slackMessageBody = {
      text: 'New booking request',
      attachments: [
        {
          text:
            `${quote.origin} -> ${quote.destination}\r\n` +
            `ID: ${quote.journeyId}\r\n` +
            `${format(quote.departureDate, 'dd MMM yyyy')} ${
              quote.returnDate ? `-> ${format(quote.returnDate, 'dd MMM yyyy')}` : ''
            }\r\nClass: ${quote.cabinType}`,
        },
        {
          text: `Passengers\r\n${formatPassengers(quote)}\r\n`,
        },
        { text: email },
      ],
    };

    await postSlackMessage(slackMessageBody);
  }
}

async function postSlackMessage(slackMessageBody: {
  text: string;
  attachments: { text: string }[];
}) {
  try {
    const response = await fetch(SLACK_FEEDBACK_HOOK_URL, {
      method: 'POST',
      body: JSON.stringify(slackMessageBody),
    });
    if (!response.ok) {
      throw new Error(`Error sending feedback to slack: ${response.statusText}`);
    }
  } catch (error) {
    console.error(`Error sending feedback to slack: ${error}`);
    throw error;
  }
}

async function sendConfirmationEmail(
  mode: string,
  email: string,
  quote: QuoteTripDetails | undefined
): Promise<void> {
  if (mode == MODE_QUOTE && quote) {
    console.log('Sending confirmation email to:', email);
    const subject = `Nous avons bien reçu votre demande de réservation pour ${quote.destination} 🚂`;
    const formatDate = (date: Date) => format(date, 'dd MMM yyyy');
    const formatPassengersMail = (quote) => {
      const passengerTypes = [
        { type: 'Adulte', count: quote.adults },
        { type: 'Jeune', count: quote.youth },
        { type: 'Enfant', count: quote.children },
        { type: 'Bébé', count: quote.infant },
      ];

      return passengerTypes
        .filter(({ count }) => count > 0)
        .map(({ type, count }) => `\r${count} ${type}${count > 1 ? 's' : ''}`)
        .join(',\r\n');
    };
    const totalPassengers = quote.adults + quote.youth + quote.children + quote.infant;
    const text = `
Bonjour,

Nous avons bien reçu votre demande de réservation pour votre voyage de ${quote.origin} à ${quote.destination}, du ${formatDate(quote.departureDate)} au ${quote.returnDate ? formatDate(quote.returnDate) : 'N/A'}.

Nous vous enverrons une confirmation par e-mail dès que votre réservation aura été traitée.

Pour modifier votre demande de réservation ou pour tout renseignement, veuillez nous contacter à l'adresse suivante : contact@railee.com.

Résumé de votre voyage :
- Ville de départ : ${quote.origin}
- Ville d'arrivée : ${quote.destination}
- Date de départ : ${formatDate(quote.departureDate)}
- Date de retour : ${quote.returnDate ? formatDate(quote.returnDate) : 'N/A'}
- Nombre total de passagers ${totalPassengers}, dont :
${formatPassengersMail(quote)}
\n et :\n
${quote.bike} Vélo${quote.bike > 1 ? 's' : ''};
\n\n- Classe : ${quote.cabinType}


Merci pour votre confiance !

Cordialement,
L'équipe Railee
`.trim();

    const logo = `${getBaseUrl()}/assets/favicons/logo_name_no_slogan.png`;
    const html = generateEmailQuote(quote, logo);

    await sendEmail(email, subject, text, html);
  }
}

function generateEmailQuote(quote, logo) {
  const formatDate = (date) => format(date, 'dd MMM yyyy', { locale: fr });
  const totalPassengers = quote.adults + quote.youth + quote.children + quote.infant;
  const instagramIcon = `${getBaseUrl()}/assets/icons/mail-icons/instagram.png`;
  const tiktokIcon = `${getBaseUrl()}/assets/icons/mail-icons/tiktok.png`;
  const linkedinIcon = `${getBaseUrl()}/assets/icons/mail-icons/linkedin.png`;

  const passengerDetails = [] as string[];
  if (quote.adults) passengerDetails.push(`Adultes: ${quote.adults}`);
  if (quote.youth) passengerDetails.push(`Jeunes: ${quote.youth}`);
  if (quote.children) passengerDetails.push(`Enfants: ${quote.children}`);
  if (quote.infant) passengerDetails.push(`Bébés: ${quote.infant}`);

  const passengerDetailsHTML = passengerDetails.join('<br>');
  const bikeDetailsHTML = quote.bike > 0 ? quote.bike : 'Non';

  return `
    <!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css?family=Lato:400,700,900" rel="stylesheet">
        <title>Confirmation de demande de réservation</title>
        <style>
            body {
                font-family: Lato, sans-serif;
                font-size: 16px;
                line-height: 1.6;
                color: #434245;
            }
            h1, h2 {
                color: #F84F39;
            }
            .content {
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
            }
            .header-logo {
                text-align: center;
                margin-bottom: 20px;
            }
            .summary-table {
                width: 100%;
                border-collapse: collapse;
                margin: 20px 0;
                border: 1px solid #ddd;
            }
            .summary-table th, .summary-table td {
                padding: 12px;
                text-align: left;
                border: 1px solid #ddd;
            }
            .summary-table th {
                background-color: #f4f4f4;
            }
            .footer {
                background-color: #f9f9f9;
                padding: 20px 0;
                text-align: center;
                font-size: 14px;
                color: #666;
            }
            .social-icons a {
                margin-right: 10px;
                text-decoration: none;
                color: #666;
            }
            .social-icons img {
                vertical-align: middle;
            }
        </style>
    </head>
    <body>
        <div style="font-size: 1px; display: none !important;">
            <div>Merci pour votre demande de réservation</div>
        </div>
        <div style="display: none; max-height: 0px; overflow: hidden;"></div>
        <table style="background-color: #ffffff; padding-top: 20px; color: #434245; width: 100%; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; border: 0; text-align: center; border-collapse: collapse;">
            <tr>
                <td style="vertical-align: top; padding: 0">
                    <center>
                        <table id="body" style="border: 0; border-collapse: collapse; margin: 0 auto; background: white; border-radius: 8px; margin-bottom: 16px;">
                            <tr>
                                <td style="width: 546px; vertical-align: top; padding-top: 32px">
                                    <div class="content">
                                        <div class="header-logo">
                                            <img height="80" src="${logo}" alt="Railee Logo">
                                        </div>
                                        <h1>Merci pour votre demande de réservation</h1>
                                        <p>Bonjour,</p>
                                        <p>Nous avons bien reçu votre demande de réservation pour votre voyage de <strong>${quote.origin}</strong> à <strong>${quote.destination}</strong>, du <strong>${formatDate(quote.departureDate)}</strong>${quote.returnDate ? ' au <strong>' + formatDate(quote.returnDate) + '</strong>' : ''}.</p>
                                        <p>Nous vous enverrons une confirmation par e-mail dès que votre réservation aura été traitée.</p>
                                        <p>Pour modifier votre demande de réservation ou pour tout renseignement, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@railee.com">contact@railee.com</a>.</p>
                                        <h2 style="margin-top: 40px;">Résumé de votre voyage :</h2>
                                        <table class="summary-table">
                                            <tr>
                                                <th>Ville de départ</th>
                                                <td>${quote.origin}</td>
                                            </tr>
                                            <tr>
                                                <th>Ville d'arrivée</th>
                                                <td>${quote.destination}</td>
                                            </tr>
                                            <tr>
                                                <th>Date de départ</th>
                                                <td>${formatDate(quote.departureDate)}</td>
                                            </tr>
                                            ${
                                              quote.returnDate
                                                ? `<tr>
                                                <th>Date de retour</th>
                                                <td>${formatDate(quote.returnDate)}</td>
                                            </tr>`
                                                : ''
                                            }
                                            <tr>
                                                <th>Nombre de passagers</th>
                                                <td>${totalPassengers}</td>
                                            </tr>
                                            <tr>
                                                <th>Détails des passagers</th>
                                                <td>${passengerDetailsHTML}</td>
                                            </tr>
                                            <tr>
                                                <th>Vélo</th>
                                                <td>${bikeDetailsHTML}</td>
                                            </tr>
                                        </table>
                                        <p>Merci de votre confiance !</p>
                                        <p>Cordialement,<br>L'équipe Railee</p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </center>
                </td>
            </tr>
        </table>
        <div class="footer">
            <div>
                <img src="${logo}" alt="Railee Logo" style="margin-right: 10px;" height="30">
                <div class="social-icons">
                    <a href="https://www.instagram.com/railee.eu/" aria-label="Instagram"><img src="${instagramIcon}" alt="Instagram" width="20" height="20"></a>
                    <a href="https://www.tiktok.com/@railee.com" aria-label="TikTok"><img src="${tiktokIcon}" alt="TikTok" width="20" height="20"></a>
                    <a href="https://www.linkedin.com/company/railee/" aria-label="LinkedIn"><img src="${linkedinIcon}" alt="LinkedIn" width="20" height="20"></a>
                </div>
            </div>
            <div style="margin-top: 20px;">
                <a href="https://www.railee.com/blog/destinations" style="margin-right: 10px;">Our blog</a>
                <a href="https://www.railee.com/en/conditions">Terms and conditions</a>
            </div>
            <div style="margin-top: 20px;">
                &copy;2024 Railee<br>
                8 bis rue Abel, 75012 Paris, France
            </div>
        </div>
    </body>
    </html>
  `;
}

export { MODE_FEEDBACK, MODE_QUOTE };
