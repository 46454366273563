'use client';

import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { useRedirectUrl } from 'context/redirectUrlProvider';
import useIphoneDetection from 'hooks/useIphoneDetection';

export const GoogleLoginBtn = () => {
  const t = useTranslations('SignUpModal');
  const [redirectUrl, ,] = useRedirectUrl();
  const iPhoneDetected = useIphoneDetection();

  const handleLogin = async () => {
    await signIn('google', { callbackUrl: redirectUrl.toString() });
  };

  if (iPhoneDetected) {
    // Google login is not supported on iPhone in app browsers
    return null;
  }

  return (
    <div>
      <Button
        variant="outline"
        id="button-gtm-google-login"
        size="lg"
        className="font-semibold mt-3 w-full"
        onClick={handleLogin}
      >
        <span id="span-gtm-google-login" className="flex flex-row gap-2.5 items-center">
          <Icons.google className="h-5 inline-block" />
          {t('oauth_google')}
        </span>
      </Button>

      {/* Separateur*/}
      <div className="flex items-center mt-4 mb-1 px-9 w-full">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="text-xs mx-2 text-gray-500">{t('or')}</span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>
    </div>
  );
};
